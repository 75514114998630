import React from "react";
import { Home } from "./Components";
import { Route, Routes } from "react-router-dom";
import Product_page from "./Components/product/Product_page";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ViewProduct from "./Components/product/ViewProduct";
import ScrollToTop from "./common/ScrollToTop";
import NotFound from "./Components/Notfound";
import Sustainability from "./Components/sustainability/Sustainability";

function App() {
  return (
    <>
      <ScrollToTop>
        <Navbar />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="product" element={<Product_page />} />
          <Route path="view-product" element={<ViewProduct />} />
          <Route path="sustainability" element={<Sustainability />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />

      </ScrollToTop>
    </>
  );
}

export default App;
