import React from "react";
import banner from "../../assets/sus_banner.jpg";
import { motion } from "framer-motion";
import Details_Section from "./Details_Section";
import Section_1 from "./Section_1";
import Life_cycle from "./Life_cycle";
import Seciton_2 from "./Seciton_2";
const Sustainability = () => {
  return (
    <>
      <div>
        <div className="relative w-full  overflow-hidden">
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <img src={banner} alt="banner" className="w-full h-96 object-cover" />
          <div className="absolute inset-0 flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className=" text-white"
            >
              <h3 className="text-white text-4xl md:text-6xl font-bold">
                Sustainability
              </h3>
            </motion.div>
          </div>
        </div>
        <Details_Section />
        <Life_cycle />
        <Seciton_2 />
        <Section_1 />
      </div>
    </>
  );
};

export default Sustainability;
