import React, { useEffect, useState } from "react";
import banner from "../../assets/Product/banner-product.jpg";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import InquiryForm from "../Form";
import { allProducts, categories } from "../../data/Product_data";
import { FaCircle } from "react-icons/fa";
import SkeletonCard from "../../common/SkeletonCard";
import { motion } from "framer-motion";

const ProductPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleCategoryClick = (index) => {
    setActive(index);
    setLoading(true);

    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds a smooth scrolling effect
    });

    // Simulate loading delay
    setTimeout(() => {
      setFilteredProducts(
        allProducts.filter((product) => product.category === categories[index])
      );
      setLoading(false);
    }, 1000);
  };

  const navigation = useNavigate();

  useEffect(() => {
    if (categories[0] === "All Products") {
      setFilteredProducts(allProducts);
    } else {
      setFilteredProducts(
        allProducts.filter((product) => product.category === categories[0])
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Banner Section */}
      <div className="relative bg-white overflow-hidden">
        <div className="absolute inset-0 bg-black opacity-50" />
        <img className="w-full h-96 object-cover" src={banner} alt="banner" />
        <div className="absolute inset-0 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className=" text-white"
          >
            <h1 className="text-white text-4xl md:text-6xl font-bold">
              Our Products
            </h1>
          </motion.div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="mt-12 container mx-auto flex md:flex-row flex-col gap-8 py-5 items-start">
        {/* Sidebar */}
        <div className="w-full md:w-1/4 md:sticky top-20">
          <h2 className="text-2xl font-bold mb-4">Main Product</h2>
          <ul className="space-y-2 bg-white rounded-md border-gray-500 border-2 overflow-hidden">
            {categories.map((category, index) => (
              <li
                key={index}
                onClick={() => handleCategoryClick(index)}
                className={`p-2 flex items-center gap-2 cursor-pointer ${
                  active === index
                    ? "bg-primary text-white"
                    : "hover:bg-secondary hover:text-white"
                }`}
              >
                <FaCircle size={10} /> {category}
                {active === index && (
                  <IoIosArrowForward className="ml-auto text-white" />
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Products Grid */}
        <div className="w-full md:w-3/4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-2">
          {loading
            ? Array.from({ length: 6 }).map((_, index) => (
                <SkeletonCard key={index} />
              ))
            : filteredProducts.map((product) => (
                <div
                  key={product.id}
                  className="bg-white shadow-md rounded-lg overflow-hidden h-fit transition-all delay-300"
                  onClick={() =>
                    navigation("/view-product", {
                      state: { id: product?.id },
                    })
                  }
                >
                  <img
                    className="w-full h-48 object-contain p-4 cursor-pointer hover:scale-105 transition-transform"
                    src={product.image}
                    alt={product.name}
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold">{product.name}</h3>
                    <div className="mt-4 flex justify-between items-center">
                      <button
                        className="text-primary font-medium flex items-center gap-1"
                        onClick={(e) => e.stopPropagation() || togglePopup()}
                      >
                        <span>🛒</span> Product Inquiry
                      </button>
                      <button
                        className="text-primary font-medium flex items-center gap-1"
                        onClick={() =>
                          navigation("/view-product", {
                            state: { id: product?.id },
                          })
                        }
                      >
                        <span>🔗</span> Read More
                      </button>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>

      <InquiryForm togglePopup={togglePopup} isOpen={isOpen} />
    </>
  );
};

export default ProductPage;
