// ViewProduct.js
import React, { useEffect, useState } from "react";
import banner from "../../assets/view_product.jpg";
import ProductInquiryForm from "./Product_inqury_form";
import Button from "../../common/Button";
import { MdDownload } from "react-icons/md";
import productData from "../../data/Product_detail";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonLoader from "../../common/ViewSkeleton";

const ViewProduct = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(true); // State to track loading
  const [getproduct, setGetproduct] = useState(null);
  const navigation = useNavigate();

  useEffect(() => {
 
    const product = productData.filter((data) => data.id === state.id)[0];
    setTimeout(() => {
      setGetproduct(product);
      setLoading(false); 
    }, 2000);
  }, [state.id]);

  if (loading) {
    return <SkeletonLoader />; 
  }
  
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/catalogue.pdf";
    link.download = "catalogue.pdf";
    link.click();
    console.log("Downloaded");
  };
  console.log(getproduct);
  return (
    <div>
      {/* Banner Section */}
      <div className="relative bg-white overflow-hidden">
        <div className="absolute inset-0 bg-black opacity-50" />
        <img
          className="w-full h-96 object-cover"
          src={banner}
          alt="Product Banner"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-white text-center">
            <h1 className="text-4xl md:text-6xl font-bold">Product Details</h1>
            <p className="mt-2 ">
              <span
                onClick={() => navigation("/product")}
                className="cursor-pointer"
              >
                Our Products {` > `}
              </span>
              <span>{getproduct.productName}</span>
            </p>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="container mx-auto my-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-4 justify-items-center items-start">
          {/* Product Image and Specification */}
          <div>
            <div className="bg-white p-5 rounded-xl shadow-md hover:shadow-xl transition-transform duration-300 hover:scale-105">
              <img
                src={getproduct.productImage}
                alt={getproduct.productName}
                className="object-contain w-full h-64 md:h-80 lg:h-96 max-w-xs sm:max-w-md lg:max-w-lg mx-auto"
              />
            </div>

            <div className="mt-4">
              <h2 className="text-3xl font-semibold mb-4">
                Product <span className="text-primary">Specification</span>
              </h2>
              <p className="text-gray-800 text-balance">
                {getproduct.productSpecification}
              </p>
            </div>
          </div>

          {/* Product Details */}
          <div className="w-full">
            <div>
              <h4 className="text-lg md:text-xl uppercase font-semibold">
                {getproduct.productName}
              </h4>
              <table className="table-auto border-collapse border border-gray-300 w-full mt-4">
                <tbody>
                  {getproduct.size === "" ? null : (
                    <>
                      <tr className="border">
                        <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36 ">
                          Size
                        </th>
                        <td className="p-2 border border-gray-300 bg-white">
                          {getproduct.size}
                        </td>
                      </tr>
                    </>
                  )}

                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      PCS/ PACKET
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      {getproduct.pcs_packet}
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      PACKET/ BOX
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      {getproduct.packet_box}
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      PCS/ BOX
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      {getproduct.pcs_box}
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      Box Size
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      {getproduct.dimension}
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      PRODUCT WEIGHT (GM)
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      {getproduct.productweight}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-4 w-fit" onClick={handleDownload}>
                <Button text="Download Catalog" Icon={MdDownload} />
              </div>
            </div>
          </div>

          {/* Inquiry Form */}
          <div className="w-full">
            <ProductInquiryForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
