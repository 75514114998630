import roundedPlate from "../assets/Product/round plate/4CP_ROUND_PLATE.png";
import roundplate from "../assets/Product/round plate/ROUND PLATE.png";
import roundplate2 from "../assets/Product/round plate/plain.png";
import threecp_round from "../assets/Product/round plate/3CP_Round_plate.png";
import fourcp_round from "../assets/Product/round plate/4CP_ROUND_PLATE.png";
import sqarplate from "../assets/Product/sqar plate/SQUARE PLATE.png";
import threecp_sqar from "../assets/Product/sqar plate/3CP SQUARE PLATE.png";
import five_sqar from "../assets/Product/sqar plate/5CP MEAL TRAY.png";
import five_sqar_large from "../assets/Product/sqar plate/5CP MEAL TRAY large.png";
import six_sqar from "../assets/Product/sqar plate/6.png";
import bowl from "../assets/Product/bowl/BOWL.png";
import bowlsqar from "../assets/Product/bowl/DONA SQUARE BOWL.png";
import donabowl from "../assets/Product/bowl/Dona.png";
import clamshall from "../assets/Product/clamshell/Clamshell.png";
import nineClamshall from "../assets/Product/clamshell/9x9 Clamshell.png";
import nineBysixClamshall from "../assets/Product/clamshell/9x6 Clamshell.png";
import threeCpclamshall from "../assets/Product/clamshell/8x3 CP CLAMSHELL.png";
import cup from "../assets/Product/cup/cup.png";
import cuplid from "../assets/Product/cup/cup-lid.png";
import fork from "../assets/Product/fork/fork.png";
import knife from "../assets/Product/fork/knif.png";


const categories = [
  "All Products",
  "Round Plate",
  "Square Plate",
  "Meal Tray",
  "Bowl",
  "Clamshell",
  "Cup",
  "Knife & Fork",
];

const allProducts = [
  {
    id: 1,
    name: `6" Round Plate`,
    image: roundplate,
    category: ["Round Plate ", "All Products"],
  },
  {
    id: 2,
    name: `7" Round Plate`,
    image: roundplate,
    category: "Round Plate",
  },
  {
    id: 3,
    name: `9" Round Plate`,
    image: roundplate,
    category: "Round Plate",
  },
  {
    id: 4,
    name: `10" Round Plate`,
    image: roundplate,
    category: "Round Plate",
  },
  {
    id: 5,
    name: `11" Round Plate`,
    image: roundplate2,
    category: "Round Plate",
  },
  {
    id: 6,
    name: `12" Round Plate`,
    image: roundplate2,
    category: "Round Plate",
  },
  {
    id: 7,
    name: `9"  3 CP ROUND PLATE`,
    image: threecp_round,
    category: "Round Plate",
  },
  {
    id: 8,
    name: `10" 3 CP ROUND PLATE`,
    image: threecp_round,
    category: "Round Plate",
  },
  {
    id: 9,
    name: `11" 4 CP ROUND PLATE`,
    image: fourcp_round,
    category: "Round Plate",
  },
  {
    id: 10,
    name: `12" 4 CP ROUND PLATE`,
    image: fourcp_round,
    category: "Round Plate",
  },
  {
    id: 11,
    name: `7" SQUARE PLATE`,
    image: sqarplate,
    category: "Square Plate",
  },
  {
    id: 12,
    name: `3 CP SQUARE MEAL TRAY`,
    image: threecp_sqar,
    category: "Meal Tray",
  },
  {
    id: 13,
    name: `5 CP MEAL TRAY SMALL`,
    image: five_sqar,
    category: "Meal Tray",
  },
  {
    id: 14,
    name: `5 CP MEAL TRAY BIG`,
    image: five_sqar_large,
    category: "Meal Tray",
  },
  { id: 15, name: `6 CP MEAL TRAY`, image: six_sqar, category: "Meal Tray" },
  { id: 16, name: `120ML ROUND BOWL`, image: bowl, category: "Bowl" },
  { id: 17, name: `180ML ROUND BOWL`, image: bowl, category: "Bowl" },
  { id: 18, name: `240ML ROUND BOWL`, image: bowl, category: "Bowl" },
  { id: 19, name: `360ML ROUND BOWL`, image: bowl, category: "Bowl" },
  { id: 20, name: `500ML SQUAR BOWL`, image: bowlsqar, category: "Bowl" },
  { id: 21, name: `4"X4" DONA BOWL`, image: donabowl, category: "Bowl" },
  { id: 22, name: `6 " CLAMSHELL`, image: clamshall, category: "Clamshell" },
  { id: 23, name: `8"  CLAMSHELL`, image: clamshall, category: "Clamshell" },
  {
    id: 24,
    name: `9" CLAMSHELL`,
    image: nineClamshall,
    category: "Clamshell",
  },
  {
    id: 25,
    name: `8" 3 CP CLAMSHELL`,
    image: threeCpclamshall,
    category: "Clamshell",
  },
  {
    id: 26,
    name: `9" 3 CP CLAMSHELL`,
    image: threeCpclamshall,
    category: "Clamshell",
  },
  {
    id: 27,
    name: `9" X 6" REACTANGLE CLAMSHELL`,
    image: nineBysixClamshall,
    category: "Clamshell",
  },
  { id: 28, name: `250 Ml DRINK CUP`, image: cup, category: "Cup" },
  { id: 29, name: `80 MM LID`, image: cuplid, category: "Cup" },
  { id: 30, name: `90 MM LID`, image: cuplid, category: "Cup" },
  { id: 31, name: `FORK`, image: fork, category: "Knife & Fork" },
  { id: 32, name: `KNIFE`, image: knife, category: "Knife & Fork" },
];

export { categories, allProducts };
