import React from "react";
import img from "../../assets/lifecycle.svg";

const steps = [
  {
    title: "Step 1: Harvesting",
    desc: "The process begins with the harvesting of raw materials, ensuring sustainability and quality sourcing.",
  },
  {
    title: "Step 2: Processing",
    desc: "The raw materials are cleaned, refined, and prepared for manufacturing.",
  },
  {
    title: "Step 3: Manufacturing",
    desc: "The processed materials are used to create finished products with precision and quality control.",
  },
  {
    title: "Step 4: Distribution",
    desc: "The products are transported and distributed to various markets and consumers globally.",
  },
  {
    title: "Step 5: Usage",
    desc: "Consumers utilize the products, benefiting from their functionality and efficiency.",
  },
  {
    title: "Step 6: Disposal",
    desc: "After use, products are either recycled, repurposed, or disposed of responsibly to minimize waste.",
  },
];

const LifeCycle = () => {
  return (
    <div className="bg-white">
      <div className="container mx-auto flex flex-col md:flex-row justify-between gap-8 p-6">
        <div className="w-full md:w-1/2 flex justify-center lg:sticky top-24 h-full  mt-10">
          <img
            src={img}
            alt="Lifecycle Process"
            className="w-full md:max-w-lg"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h1 className="text-3xl font-bold mb-6">Our Life Cycle</h1>
          <div className="flex flex-col space-y-5">
            {steps.map((step, index) => (
              <div key={index} className="flex flex-col items-start ">
                <span className="text-xl font-semibold text-primary">
                  {step.title}
                </span>
                <p className="text-black">{step.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeCycle;
