import React from "react";
import img from "../../assets/sustablity-img.jpg";
const Details_Section = () => {
  return (
    <>
      <div className="container py-10 mt-5 flex flex-col md:flex-row justify-between gap-5">
        <div className="w-full md:w-[60%] flex flex-col gap-4">
          <div className="flex flex-col justify-start gap-3 md:gap-4">
            <h1 className="text-3xl font-bold w-fit ">Our Sustainablity</h1>
            <p className="w-full md:w-[60%] text-justify">
              Bagasse products, which are made from sugarcane waste, can be a
              more sustainable alternative to traditional disposable products
              made from non-renewable resources such as plastic or styrofoam.
              Here are some key sustainability factors to consider when it comes
              to bagasse products and their impact on the environment.
            </p>
          </div>
        </div>
        <div className="w-full md:w-[40%]">
          <img
            src={img}
            alt="sustainablity"
            className="w-full md:max-w-md rounded-lg"
          />
        </div>
      </div>
    </>
  );
};

export default Details_Section;
