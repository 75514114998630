const SkeletonCard = () => (
    <div className="bg-gray-200 animate-pulse shadow-md rounded-lg overflow-hidden h-fit">
      <div className="w-full h-48 bg-gray-300"></div>
      <div className="p-4">
        <div className="h-4 bg-gray-400 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-gray-400 rounded w-1/2"></div>
        <div className="mt-4 flex justify-between">
          <div className="h-6 bg-gray-300 rounded w-1/3"></div>
          <div className="h-6 bg-gray-300 rounded w-1/3"></div>
        </div>
      </div>
    </div>
  );
  export default SkeletonCard;