import React from "react";
import img from "../assets/lifecycle_img.png";
import Heading from "../common/Heading";
import { motion } from 'framer-motion';

const LifeCycle_Section = () => {
  return (
    <div className="bg-white mt-20 pb-10">
      <div className="text-center ">
        <div className="py-10">
          <Heading text={"Life Cycle Of Bagaas Product"} />
        </div>
        <img
          src={img}
          alt="lifecycle"
          className="w-full md:max-w-3xl mx-auto pb-10"
        />
        <motion.div className="text-3xl font-bold text-wrap lg:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary"
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 1.5, delay: 0.5 }}>
          "From Earth to Earth" 
        </motion.div>
      </div>
    </div>
  );
};

export default LifeCycle_Section;
