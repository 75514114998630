import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom"; // Import useLocation
import logo from "../assets/logo_png.png";
import InquiryForm from "./Form";

const Navbar = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current location

  const handleToggle = () => {
    setIsOpen1(!isOpen1);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const hideLinks = location.pathname !== "/"; // Example: Only show links on the homepage

  return (
    <>
      <nav className="bg-white fixed w-full shadow-lg z-50 top-0">
        <div className="container mx-auto px-4 flex justify-between items-center h-16">
          <a href="/">
            <img src={logo} alt="logo" className="md:w-32 w-28" />
          </a>

          {/* Desktop Menu */}
          <ul className="hidden md:flex space-x-6 items-center">
            <li className="relative group">
              <a
                href="/"
                className="hover:text-gray-400 transition duration-300 cursor-pointer"
              >
                Home
              </a>
            </li>
            <li className="relative group">
              <a
                href="/sustainability"
                className="hover:text-gray-400 transition duration-300 cursor-pointer"
              >
                Sustainablity
              </a>
            </li>

            {!hideLinks && (
              <>
                <li className="relative group">
                  <Link
                    to="about"
                    smooth={true}
                    duration={500}
                    className="hover:text-gray-400 transition duration-300 cursor-pointer capitalize"
                  >
                    about
                  </Link>
                </li>
              </>
            )}
            <>
              <li className="relative group">
                <a
                  href="/product"
                  className="hover:text-gray-400 transition duration-300 cursor-pointer"
                >
                  Products
                </a>
              </li>
            </>

            {!hideLinks && (
              <>
                <li className="relative group">
                  <Link
                    to="services"
                    smooth={true}
                    duration={500}
                    className="hover:text-gray-400 transition duration-300 cursor-pointer capitalize"
                  >
                    services
                  </Link>
                </li>
              </>
            )}
            <li className="relative group">
              <div
                onClick={togglePopup}
                className="hover:text-gray-400 transition duration-300 cursor-pointer"
              >
                Contact Us
              </div>
            </li>
          </ul>
          <div></div>
          {/* Mobile Menu Icon */}
          <div
            className="md:hidden text-2xl cursor-pointer"
            onClick={handleToggle}
          >
            {isOpen1 ? <FaTimes /> : <FaBars />}
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen1 && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "tween", duration: 0.5 }}
            className="absolute top-0 right-0 w-3/4 bg-secondary text-white font-semibold h-screen shadow-lg"
          >
            <div className="flex justify-end items-center px-6 py-4">
              <div className="text-2xl cursor-pointer" onClick={handleToggle}>
                <FaTimes />
              </div>
            </div>
            <ul className="space-y-6 mt-8 px-6 text-lg">
              <li>
                <a href="/" className="hover:text-gray-400 cursor-pointer">
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/sustainability"
                  className="hover:text-gray-400 cursor-pointer"
                >
                  Sustainablity
                </a>
              </li>
              {!hideLinks && (
                <>
                  <li>
                    <Link
                      to="about"
                      smooth={true}
                      duration={500}
                      className="block hover:text-gray-400 cursor-pointer capitalize"
                      onClick={handleToggle}
                    >
                      About
                    </Link>
                  </li>
                </>
              )}
              <>
                <li>
                  <a
                    href="/product"
                    className="hover:text-gray-400 cursor-pointer"
                  >
                    Products
                  </a>
                </li>
              </>
              {!hideLinks && (
                <>
                  <li>
                    <Link
                      to="services"
                      smooth={true}
                      duration={500}
                      className="block hover:text-gray-400 cursor-pointer capitalize"
                      onClick={handleToggle}
                    >
                      Services
                    </Link>
                  </li>
                </>
              )}
              <li>
                <div
                  onClick={togglePopup}
                  className="hover:text-gray-400 cursor-pointer"
                >
                  Contact Us
                </div>
              </li>
            </ul>
          </motion.div>
        )}
      </nav>
      <InquiryForm togglePopup={togglePopup} isOpen={isOpen} />
    </>
  );
};

export default Navbar;
