import React from "react";
import img1 from "../../assets/Sustainable-icon.png";
import img2 from "../../assets/Closed-loop-economy-icon.png";
import img3 from "../../assets/Biodegradable-icon.png";
import img4 from "../../assets/Customizable-icon.png";

const Commitment = [
  {
    title: "Sustainable",
    desc: "Made from renewable resources",
    icon: img1,
  },
  {
    title: "Closed loop circular economy",
    desc: "Maximizing values and recovering them at the end of each material's service life",
    icon: img2,
  },
  {
    title: "Biodegradable & compostable",
    desc: "Providing a wide range of biodegradable & compostable packaging for various needs",
    icon: img3,
  },
  {
    title: "Fully customizable",
    desc: "Customizable eco-friendly packaging to suit your preferences",
    icon: img4,
  },
];

const Seciton_2 = () => {
  return (
    <>
      <div className="container flex justify-center items-center py-10 flex-col">
        <span className="text-center font-bold text-2xl sm:text-3xl md:text-4xl lg:text-4xl capitalize">
          Committed to eco-friendly quality products
        </span>
        <div>
          <div className="flex flex-col md:flex-row gap-5 mt-6">
            {Commitment.map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-center  mt-10 text-center w-full md:w-1/4 gap-2"
              >
                <img
                  src={item.icon}
                  alt="icon"
                  className="w-16 h-16 lg:w-24 lg:h-24  hover:-translate-y-2 transition-all delay-200"
                />
                <span className="text-xl font-bold mt-2">{item.title}</span>
                <p>{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Seciton_2;
