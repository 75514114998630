import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-primary">404</h1>
        <h2 className="mt-4 text-4xl font-bold text-gray-800">
          Page Not Found
        </h2>
        <p className="mt-2 text-lg text-secondary">
          Sorry, the page you're looking for doesn't exist.
        </p>
        <button
          onClick={() => (window.location.href = "/")}
          className="mt-6 px-6 py-3 bg-primary text-white rounded-lg shadow-lg hover:bg-secondary transition duration-300"
        >
          Go Back Home
        </button>
      </div>
     
    </div>
  );
};

export default NotFound;
