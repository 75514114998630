import React from "react";
import img1 from '../assets/diff/biodegradable.png'
import img2 from '../assets/diff/environmentalism.png'
import img3 from '../assets/diff/non-toxic.png'
import img4 from '../assets/diff/oven.png'
import img5 from '../assets/diff/plastic-free.png'
import img6 from '../assets/diff/snowflake.png'

const differences = [
  {
    id: 1,
    title: "Biodegradable",
    image: img1,
  },
  {
    id: 2,
    title: "100% Eco Friendly",
    image: img2,
  },
  {
    id: 3,
    title: "Non-Toxic",
    image: img3,
  },
  {
    id: 4,
    title: "Oven Safe",
    image: img4,
  },
  {
    id: 5,
    title: "Plastic Free",
    image: img5,
  },
  {
    id: 6,
    title: "Freeze Safe",
    image: img6,
  },
]

const Difference_Section = () => {
  return (
    <div className="py-10 bg-primary">
      <div className="container text-white py-10">
        <h2 className="text-3xl lg:text-4xl text-transparent bg-clip-text  text-white text-center">
          What makes us different?
        </h2>
        <div className="text-center mt-5 text-lg md:text-xl">
          We create compostable packaging solutions for a circular economy where
          there is no waste. It starts with responsibly sourced materials,
          continues with environmentally certified manufacturing processes, and
          ends with disposal options that see resources reused or regenerated.
        </div>
        <div className="mt-20 flex flex-row gap-10 flex-wrap lg:justify-between justify-center">
          {differences.map((diff) => (
            <div key={diff.id} className="flex flex-col items-center hover:scale-110 cursor-pointer transition-transform duration-300">
              <img src={diff.image} alt={diff.title} className="md:h-28 h-16 " />
              <h3 className="text-xl mt-3">{diff.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Difference_Section;
