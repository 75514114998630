import { motion } from 'framer-motion';

const Heading = ({ text }) => {
  return (
    <>
     
      {/* Animated Text with Typewriter Effect */}
      <motion.h1
        className="text-3xl text-wrap lg:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, delay: 0.5 }}
      >
        {text.split('').map((char, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: index * 0.05,
              type: 'spring',
              stiffness: 200,
              damping: 15,
            }}
          >
            {char}
          </motion.span>
        ))}
      </motion.h1>
    </>
  );
};

export default Heading;
