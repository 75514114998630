// Product_detail.js
import roundedPlate from "../assets/Product/round plate/4CP_ROUND_PLATE.png";
import roundplate from "../assets/Product/round plate/ROUND PLATE.png";
import roundplate2 from "../assets/Product/round plate/plain.png";
import threecp_round from "../assets/Product/round plate/3CP_Round_plate.png";
import fourcp_round from "../assets/Product/round plate/4CP_ROUND_PLATE.png";
import sqarplate from "../assets/Product/sqar plate/SQUARE PLATE.png";
import threecp_sqar from "../assets/Product/sqar plate/3CP SQUARE PLATE.png";
import five_sqar from "../assets/Product/sqar plate/5CP MEAL TRAY.png";
import five_sqar_large from "../assets/Product/sqar plate/5CP MEAL TRAY large.png";
import six_sqar from "../assets/Product/sqar plate/6.png";
import bowl from "../assets/Product/bowl/BOWL.png";
import bowlsqar from "../assets/Product/bowl/DONA SQUARE BOWL.png";
import donabowl from "../assets/Product/bowl/Dona.png";
import clamshall from "../assets/Product/clamshell/Clamshell.png";
import nineClamshall from "../assets/Product/clamshell/9x9 Clamshell.png";
import nineBysixClamshall from "../assets/Product/clamshell/9x6 Clamshell.png";
import threeCpclamshall from "../assets/Product/clamshell/8x3 CP CLAMSHELL.png";
import cup from "../assets/Product/cup/cup.png";
import cuplid from "../assets/Product/cup/cup-lid.png";
import fork from "../assets/Product/fork/fork.png";
import knife from "../assets/Product/fork/knif.png";
import { i } from "framer-motion/client";

const productData = [
  {
    id: 1,
    productImage: roundplate,
    productName: `6" Round Plate`,
    size: "6 x 6",
    pcs_packet: `25`,
    packet_box: "60",
    pcs_box: "1500",
    dimension: "L X W X H - 480 x 320 x 330 mm",
    productweight: "6.0 gm",
    productSpecification: "",
  },
  {
    id: 2,
    productImage: roundplate,
    productName: `7" Round Plate`,
    size: "7 x 7",
    pcs_packet: `25`,
    packet_box: "40",
    pcs_box: "1000",
    dimension: "L X W X H - 365 x 185 x 270 mm",
    productweight: "8.0 gm",
    productSpecification: "",
  },
  {
    id: 3,
    productImage: roundplate,
    productName: `9" Round Plate`,
    size: "9 x 9",
    pcs_packet: `25`,
    packet_box: "40",
    pcs_box: "1000",
    dimension: "L X W X H - 455 x 455 x 355 mm",
    productweight: "14.0 gm",
    productSpecification: "",
  },
  {
    id: 4,
    productImage: roundplate,
    productName: `10" Round Plate`,
    size: "10 x 10",
    pcs_packet: `50`,
    packet_box: "10",
    pcs_box: "500",
    dimension: "L X W X H - 508 x 254 x 305 mm",
    productweight: "18.0 gm",
    productSpecification: "",
  },
  {
    id: 5,
    productImage: roundplate,
    productName: `11" Round Plate`,
    size: "11 x 11",
    pcs_packet: `50`,
    packet_box: "10",
    pcs_box: "500",
    dimension: "L X W X H - 559 x 280 x 375 mm",
    productweight: "23.0 gm",
    productSpecification: "",
  },
  {
    id: 6,
    productImage: roundplate,
    productName: `12" Round Plate`,
    size: "12 x 12",
    pcs_packet: `50`,
    packet_box: "10",
    pcs_box: "500",
    dimension: "L X W X H - 610 x 305 x 458 mm",
    productweight: "29.0 gm",
    productSpecification: "",
  },
  {
    id: 7,
    productImage: threecp_round,
    productName: `9" 3 CP ROUND PLATE`,
    size: "9 x 9",
    pcs_packet: `50`,
    packet_box: "10",
    pcs_box: "500",
    dimension: "L X W X H - 465 x 235 x 350 mm",
    productweight: "14.5 gm",
    productSpecification: "",
  },
  {
    id: 8,
    productImage: threecp_round,
    productName: `10" 3 CP ROUND PLATE`,
    size: "10 x 10",
    pcs_packet: `50`,
    packet_box: "10",
    pcs_box: "500",
    dimension: "L X W X H - 520 x 381 x 280 mm",
    productweight: "18.0 gm",
    productSpecification: "",
  },
  {
    id: 9,
    productImage: fourcp_round,
    productName: `11" 4 CP ROUND PLATE`,
    size: "11 x 11",
    pcs_packet: `25`,
    packet_box: "20",
    pcs_box: "500",
    dimension: "L X W X H - 560 x 560 x 260 mm",
    productweight: "23.0 gm",
    productSpecification: "",
  },
  {
    id: 10,
    productImage: fourcp_round,
    productName: `12" 4 CP ROUND PLAT`,
    size: "12 x 12",
    pcs_packet: `50`,
    packet_box: "10",
    pcs_box: "500",
    dimension: "L X W X H - 610 x 305 x 420 mm",
    productweight: "30.0 gm",
    productSpecification: "",
  },
  {
    id: 11,
    productImage: sqarplate,
    productName: `7" Square Plate`,
    size: "7 x 7",
    pcs_packet: `25`,
    packet_box: "60",
    pcs_box: "1500",
    dimension: "L X W X H - 535 x 355 x 320 mm",
    productweight: "9.0 gm",
    productSpecification: "",
  },
  {
    id: 12,
    productImage: threecp_sqar,
    productName: `3 CP SQUARE MEAL TRAY `,
    size: "10 x 10",
    pcs_packet: `50`,
    packet_box: "10",
    pcs_box: "500",
    dimension: "L X W X H - 450 x 450 x 425 mm",
    productweight: "17.0 gm",
    productSpecification: "",
  },
  {
    id: 13,
    productImage: five_sqar,
    productName: `5 CP MEAL TRAY BIG`,
    size: "",
    pcs_packet: `25`,
    packet_box: "20",
    pcs_box: "500",
    dimension: "L X W X H - 460 x 310 x 465 mm",
    productweight: "33.0 gm",
    productSpecification: "",
  },
  {
    id: 14,
    productImage: five_sqar_large,
    productName: `5 CP MEAL TRAY SMALL`,
    size: "",
    pcs_packet: `25`,
    packet_box: "20",
    pcs_box: "500",
    dimension: "L X W X H - 545 x 222 x 450 mm",
    productweight: "23.0 gm",
    productSpecification: "",
  },
  {
    id: 15,
    productImage: six_sqar,
    productName: `6 CP MEAL TRAY`,
    size: "",
    pcs_packet: `50`,
    packet_box: "6",
    pcs_box: "300",
    dimension: "L X W X H - 470 x 313 x 331 mm",
    productweight: "40.0 gm",
    productSpecification: "",
  },
  {
    id: 16,
    productImage: bowl,
    productName: `120ML ROUND BOWL`,
    size: "",
    pcs_packet: `50`,
    packet_box: "45",
    pcs_box: "2250",
    dimension: "L X W X H - 520 x 315 x 390 mm",
    productweight: "4.0 gm",
    productSpecification: "",
  },
  {
    id: 17,
    productImage: bowl,
    productName: `180ML ROUND BOWL`,
    size: "",
    pcs_packet: `50`,
    packet_box: "40",
    pcs_box: "2000",
    dimension: "L X W X H - 545 x 420 x 350 mm",
    productweight: "5.0 gm",
    productSpecification: "",
  },
  {
    id: 18,
    productImage: bowl,
    productName: `240ML ROUND BOWL`,
    size: "",
    pcs_packet: `50`,
    packet_box: "30",
    pcs_box: "1500",
    dimension: "L X W X H - 584 x 381 x 482 mm",
    productweight: "7.0 gm",
    productSpecification: "",
  },
  {
    id: 19,
    productImage: bowl,
    productName: `360ML ROUND BOWL`,
    size: "",
    pcs_packet: `50`,
    packet_box: "36",
    pcs_box: "1800",
    dimension: "L X W X H - 565 x 425 x 435 mm",
    productweight: "9.0 gm",
    productSpecification: "",
  },
  {
    id: 20,
    productImage: bowlsqar,
    productName: `500ML SQUARE BOWL`,
    size: "",
    pcs_packet: `50`,
    packet_box: "36",
    pcs_box: "1900",
    dimension: "L X W X H - 545 x 420 x 350 mm",
    productweight: "5.0 gm",
    productSpecification: "",
  },
  {
    id: 21,
    productImage: donabowl,
    productName: `4"X4" DONA BOWL`,
    size: "4x4",
    pcs_packet: `50`,
    packet_box: "40",
    pcs_box: "2000",
    dimension: "L X W X H - 420 x 220 x 480 mm",
    productweight: "5.0 gm",
    productSpecification: "",
  },
  {
    id: 22,
    productImage: clamshall,
    productName: `6 " CLAMSHELL`,
    size: "6x6",
    pcs_packet: `50`,
    packet_box: "10",
    pcs_box: "500",
    dimension: "L X W X H - 312 x 312 x 420 mm",
    productweight: "21.0 gm",
    productSpecification: "",
  },
  {
    id: 23,
    productImage: clamshall,
    productName: `8" CLAMSHELL`,
    size: "8x8",
    pcs_packet: `50`,
    packet_box: "4",
    pcs_box: "200",
    dimension: "L X W X H - 460 x 410 x 215 mm",
    productweight: "34.0 gm",
    productSpecification: "",
  },
  {
    id: 24,
    productImage: donabowl,
    productName: `9" CLAMSHELL`,
    size: "9x9",
    pcs_packet: `50`,
    packet_box: "4",
    pcs_box: "200",
    dimension: "L X W X H - 490 x 470 x 238 mm",  
    productweight: "44.0 gm",
    productSpecification: "",
  },
  {
    id: 25,
    productImage: threeCpclamshall,
    productName: `8" 3 CP CLAMSHELL`,
    size: "8x8",
    pcs_packet: `50`,
    packet_box: "4",
    pcs_box: "200",
    dimension: "L X W X H - 510 x 415 x 220 mm",  
    productweight: "34.0 gm",
    productSpecification: "",
  },
  {
    id: 26,
    productImage: nineClamshall,
    productName: `9" 3 CP CLAMSHELL`,
    size: "9x9",
    pcs_packet: `50`,
    packet_box: "4",
    pcs_box: "200",
    dimension: "L X W X H - 465 x 460 x 240 mm",
    productweight: "44.0 gm",
    productSpecification: "",
  },
  {
    id: 27,
    productImage: nineBysixClamshall,
    productName: `9" X 6" REACTANGLE CLAMSHELL`,
    size: "",
    pcs_packet: `50`,
    packet_box: "5",
    pcs_box: "250",
    dimension: "L X W X H - 300 x 240 x 400 mm",
    productweight: "30.0 gm",
    productSpecification: "",
  },
  {
    id: 28,
    productImage: cup,
    productName: `250 Ml DRINK CUP`,
    size: "",
    pcs_packet: `50`,
    packet_box: "20",
    pcs_box: "1000",
    dimension: "L X W X H - 550 x 460 x 500 mm",
    productweight: "9.0 gm",
    productSpecification: "",
  },
  {
    id: 29,
    productImage: cuplid,
    productName: `80 MM LID `,
    size: "",
    pcs_packet: `50`,
    packet_box: "40",
    pcs_box: "2000",
    dimension: "L X W X H - 368 x 445 x 470 mm",
    productweight: "3.0 gm",
    productSpecification: "",
  },
  {
    id: 30,
    productImage: cuplid,
    productName: `90 MM LID `,
    size: "",
    pcs_packet: `50`,
    packet_box: "40",
    pcs_box: "2000",
    dimension: "L X W X H - 406 x 495 x 470 mm",
    productweight: "5.0 gm",
    productSpecification: "",
  },
  {
    id: 31,
    productImage: fork,
    productName: `FORK`,
    size: "",
    pcs_packet: ``,
    packet_box: "",
    pcs_box: "",
    dimension: "",
    productweight: "2.0 gm",
    productSpecification: "",
  },
  {
    id: 32,
    productImage: knife,
    productName: `KNIFE`,
    size: "",
    pcs_packet: ``,
    packet_box: "",
    pcs_box: "",
    dimension: "",
    productweight: "2.0 gm",
    productSpecification: "",
  },
  
];

export default productData;
