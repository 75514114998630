// SkeletonLoader.js
import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="animate-pulse space-y-4">
      {/* Banner Section */}
      <div className="relative bg-white overflow-hidden">
        <div className="absolute inset-0 bg-black opacity-50" />
        <div className="w-full h-96 bg-gray-300 rounded-md" />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-white text-center">
            <div className="h-8 w-40 bg-gray-300 rounded mx-auto mb-2"></div>
            <div className="h-4 w-32 bg-gray-300 rounded mx-auto"></div>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="container py-10 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-4 justify-items-center items-start">
          {/* Product Image and Specification */}
          <div className="bg-gray-300 p-5 rounded-xl shadow-md h-64 w-full md:h-80 lg:h-96 max-w-xs sm:max-w-md lg:max-w-lg">
            <div className=" bg-gray-300 rounded-md mb-4" />
          </div>
          {/* Product Details */}
          <div className="w-full">
            <div className="h-6 w-1/2 bg-gray-300 rounded-md mb-4"></div>
            <div className="w-full">
              <table className="table-auto border-collapse border border-gray-300 w-full mt-4">
                <tbody>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </td>
                  </tr>
                  <tr className="border">
                    <th className="text-left p-2 bg-gray-100 border border-gray-300 w-36">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </th>
                    <td className="p-2 border border-gray-300 bg-white">
                      <div className="h-4 w-3/4 bg-gray-300 rounded-md" />
                    </td>
                  </tr>
                  {/* Add other rows as necessary */}
                </tbody>
              </table>
            </div>
          </div>

          {/* Inquiry Form */}
          <div className="w-full">
            <div className="h-60 w-full bg-gray-300 rounded-md" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
