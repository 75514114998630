import React from "react";
import img from "../../assets/sus_details.jpg";

const steps = [
  {
    title: "100% Renewable:",
    desc: "Bagasse is made from sugarcane pulp, a byproduct of sugar production. By repurposing this natural material, we reduce waste and conserve resources.",
  },
  {
    title: " Biodegradable & Compostable::",
    desc: "Our bagasse products break down naturally in composting conditions, unlike plastic, which lingers in landfills and oceans for centuries.",
  },
  {
    title: "Plastic-Free Future:",
    desc: "By replacing harmful materials with bagasse, we're helping to curb pollution and protect ecosystems.",
  },
];
const Section_1 = () => {
  return (
    <>
      <div className="flex container flex-col md:flex-row md:justify-between justify-center py-20 gap-5 md:gap-1">
        <div className="lg:sticky top-24 h-full">
          <img
            src={img}
            alt="details"
            className="w-full h-[300px] md:h-[500px] rounded-lg object-cover"
          />
        </div>
        <div className="w-full lg:w-1/2">
          <div>
            <div className="text-xl sm:text:2xl md:text:3xl lg:text:4xl font-bold mb-1">
              Our Sustainability Commitment 🌿
            </div>
            <p>
              At Biotreat, we’re on a mission to redefine disposables by turning
              sugarcane waste into something extraordinary. Our eco-friendly
              bagasse products are designed to protect the planet while offering
              reliable alternatives to harmful plastics and styrofoam. <br />{" "}
              Every product we create supports a future where waste becomes
              opportunity, and nature takes center stage.
            </p>
          </div>
          <div>
            <div className="text-xl sm:text:2xl md:text:3xl lg:text:4xl font-bold my-3">
              Why Choose Bagasse? 🌱
            </div>
            <div>
              {steps.map((step, index) => (
                <div key={index} className="mb-3">
                  <div className="flex gap-1 items-center">
                    <div className="text-xl font-bold">{index + 1}.</div>
                    <div className="text-xl font-bold">{step.title}</div>
                  </div>
                  <p>{step.desc}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="text-xl sm:text:2xl md:text:3xl lg:text:4xl font-bold mb-1">
              Join the Movement 🌍
            </div>
            <p>
              Together, we can create a greener tomorrow. Every choice matters,
              and by choosing Biotreat, you’re taking a stand against plastic
              waste. Let’s save our soil, protect our waters, and ensure a
              cleaner Earth for generations to come.
            </p>
          </div>
          <div className="text-xl sm:text:2xl md:text:3xl lg:text:4xl font-bold mt-4">
            Choose Biotreat. Choose the Earth. 💚
          </div>
        </div>
      </div>
    </>
  );
};

export default Section_1;
